/*
 *
 * GiftBox constants
 *
 */
import { GiftType } from '@17live/app/containers/GiftBox/GiftBox.enums';

import { GiftTab } from './types';

export const SWITCH_TAB: string = 'app/GiftBox/SWITCH_TAB';
export const SEND_GIFT: string = 'app/GiftBox/SEND_GIFT';
export const SET_SENT_RESPONSE: string = 'app/GiftBox/SET_SENT_RESPONSE';
export const SET_SEND_ERROR: string = 'app/GiftBox/SET_SEND_ERROR';
export const SEND_BAGGAGE: string = 'app/GiftBox/SEND_BAGGAGE';
export const SEND_GOODS: string = 'app/GiftBox/SEND_GOODS';
export const SEND_GIFT_TO_ALL_IN_GROUP_CALL: string =
  'app/GiftBox/SEND_GIFT_TO_ALL_IN_GROUP_CALL';

export const SET_DEFAULT_GIFT_TAB: string = 'app/GiftBox/SET_DEFAULT_GIFT_TAB';
export const GET_DEFAULT_GIFT_TAB: string = 'app/GiftBox/GET_DEFAULT_GIFT_TAB';

/**
 * Gift tab
 */
export const GIFT_TAB_HISTORY: GiftTab = 'history';
export const GIFT_TAB_NORMAL: GiftTab = 'normal';
export const GIFT_TAB_NO_LABEL: GiftTab = 'noLabel'; // recommended
export const GIFT_TAB_LUCKY_BAG: GiftTab = 'luckyBag';
export const GIFT_TAB_SLOT: GiftTab = 'slot';
export const GIFT_TAB_TREASURE_CHEST: GiftTab = 'treasureChest';
export const GIFT_TAB_POKE: GiftTab = 'poke';
export const GIFT_TAB_POLL: GiftTab = 'poll';
export const GIFT_TAB_FRESH: GiftTab = 'fresh';
export const GIFT_TAB_BAGGAGE: GiftTab = 'baggage';
export const GIFT_TAB_EVENT: GiftTab = 'event';
export const GIFT_TAB_ARMY: GiftTab = 'army';
export const GIFT_TAB_VOUCHER: GiftTab = 'voucher';
export const GIFT_TAB_ELITE: GiftTab = 'elite';
export const GIFT_TAB_BIRTHDAY: GiftTab = 'birthday';
export const GIFT_TAB_TEXTURE: GiftTab = 'texture';
export const GIFT_TAB_GOODS: GiftTab = 'goods';
export const GIFT_TAB_LEVEL_UP: GiftTab = 'levelUp';
export const GIFT_TAB_HAND_DRAW_STICKER: GiftTab = 'handDrawSticker';
export const GIFT_TAB_GAME: GiftTab = 'game';
export const GIFT_TAB_LAYOUT: GiftTab = 'layout';
export const GIFT_TAB_CUSTOM: GiftTab = 'custom';
export const GIFT_TAB_TOPIC = 'topic';
export const GIFT_TAB_EXTRAVAGANT = 'extravagant';
export const GIFT_TAB_AUDIO = 'audio';
export const GIFT_TAB_HEAVENLY = 'Heavenly';
export const GIFT_TAB_GFA = 'GFA';
export const GIFT_TAB_DEFAULT = 'default';

export const GIFT_TAB_IDS = Object.freeze({
  [GIFT_TAB_NORMAL]: 'Product', // 實體禮物
  [GIFT_TAB_LUCKY_BAG]: 'luckyBag', // 隨機禮
  [GIFT_TAB_SLOT]: 'TreasureChest', // 好運禮
  [GIFT_TAB_POKE]: 'PokeBack', // 回戳禮
  [GIFT_TAB_POLL]: 'Poll', // 投票
  [GIFT_TAB_FRESH]: 'Newbie', // 新手禮
  [GIFT_TAB_EVENT]: 'Event', // 活動禮
  [GIFT_TAB_ARMY]: 'army', // 專屬禮
  [GIFT_TAB_BAGGAGE]: 'Baggage', // 背包
  [GIFT_TAB_BIRTHDAY]: 'Birthday', // 生日禮
  [GIFT_TAB_HISTORY]: 'Recent', // 最近送出
  [GIFT_TAB_TEXTURE]: 'Texture', // 濾鏡禮
  [GIFT_TAB_GOODS]: 'Goods', // 限量禮物
  [GIFT_TAB_LEVEL_UP]: 'LevelUp', // 升級禮
  [GIFT_TAB_HAND_DRAW_STICKER]: 'HandDrawSticker', // 塗鴉禮
  [GIFT_TAB_GAME]: 'Game', // 遊戲禮
  [GIFT_TAB_LAYOUT]: 'Layout', // 佈景禮
  [GIFT_TAB_TOPIC]: 'Topic', // 話題禮
  [GIFT_TAB_EXTRAVAGANT]: 'Extravagant', // 經典禮
  [GIFT_TAB_AUDIO]: 'Audio', // 音效禮
  [GIFT_TAB_HEAVENLY]: 'Heavenly', // 奢華禮
  [GIFT_TAB_DEFAULT]: 'default', // when use concert mode
});

// slack thread: https://17media.slack.com/archives/C029P2CJG91/p1667296319569719
// backend enum ref: https://github.com/17media/api/blob/4283ed1e99497c919a9993c58e6e6d1dc69e34c2/models/gift/gift.pb.go#L395-L418
export const TAB_TYPES: {
  [key: string]: number;
} = Object.freeze({
  [GIFT_TAB_NORMAL]: 0, // 實體禮物
  [GIFT_TAB_NO_LABEL]: 1,
  [GIFT_TAB_LUCKY_BAG]: 2, // 隨機禮
  [GIFT_TAB_SLOT]: 3, // 好運禮
  [GIFT_TAB_POKE]: 4, // 回戳禮
  [GIFT_TAB_POLL]: 5, // 投票
  [GIFT_TAB_FRESH]: 6,
  [GIFT_TAB_EVENT]: 7, // 活動禮
  [GIFT_TAB_ARMY]: 8, // 專屬禮
  [GIFT_TAB_VOUCHER]: 9,
  [GIFT_TAB_BAGGAGE]: 10, // 背包
  [GIFT_TAB_ELITE]: 11,
  [GIFT_TAB_BIRTHDAY]: 12, // 生日禮
  [GIFT_TAB_HISTORY]: 13, // 最近送出
  [GIFT_TAB_TEXTURE]: 14, // 濾鏡禮
  [GIFT_TAB_GOODS]: 15, // 限量禮物
  [GIFT_TAB_LEVEL_UP]: 16, // 升級禮
  [GIFT_TAB_HAND_DRAW_STICKER]: 17, // 塗鴉禮
  [GIFT_TAB_GAME]: 18, // 遊戲禮
  [GIFT_TAB_LAYOUT]: 19, // 佈景禮
  [GIFT_TAB_CUSTOM]: 9999, // 自訂禮物, 來源是liveStream內的customEvent
});

export const GIFT_TAB_TYPES: {
  [key: number]: string;
} = Object.keys(TAB_TYPES).reduce(
  (tabs, tab) => ({
    ...tabs,
    [TAB_TYPES[tab]]: tab,
  }),
  {}
);

export const GIFT_TAB_TRACK_SOURCE_URL = Object.freeze({
  [GIFT_TAB_FRESH]: 'Newbie', // 新手禮
  [GIFT_TAB_LEVEL_UP]: 'LvUp', // 升級禮
  [GIFT_TAB_TOPIC]: 'Topic', // 話題禮
  [GIFT_TAB_HEAVENLY]: 'Heavenly', // 奢華禮
  [GIFT_TAB_EXTRAVAGANT]: 'Extravagant', // 經典禮
  [GIFT_TAB_BIRTHDAY]: 'Birthday', // 生日禮
  [GIFT_TAB_AUDIO]: 'Audio', // 音效禮
  [GIFT_TAB_GFA]: 'GFA', // 金羽獎
});

/**
 * TODO: website not support interactive gift now
 * 1. GIFT_TAB_HAND_DRAW_STICKER
 * 2. GIFT_TAB_GAME
 */
export const GIFT_TAB_TYPES_WHITE_LIST: GiftTab[] = [
  GIFT_TAB_NORMAL,
  GIFT_TAB_NO_LABEL,
  GIFT_TAB_LUCKY_BAG,
  GIFT_TAB_SLOT,
  GIFT_TAB_POKE,
  GIFT_TAB_POLL,
  GIFT_TAB_FRESH,
  GIFT_TAB_EVENT,
  GIFT_TAB_ARMY,
  GIFT_TAB_VOUCHER,
  GIFT_TAB_BAGGAGE,
  GIFT_TAB_ELITE,
  GIFT_TAB_BIRTHDAY,
  GIFT_TAB_HISTORY,
  GIFT_TAB_TEXTURE,
  GIFT_TAB_GOODS,
  GIFT_TAB_LEVEL_UP,
  // GIFT_TAB_HAND_DRAW_STICKER,
  // GIFT_TAB_GAME,
  // GIFT_TAB_LAYOUT,
  GIFT_TAB_DEFAULT,
];

export const CUSTOM_GIFT_TAB_IDS_WHITE_LIST = [
  GIFT_TAB_IDS[GIFT_TAB_LUCKY_BAG],
  GIFT_TAB_IDS[GIFT_TAB_SLOT],
  GIFT_TAB_IDS[GIFT_TAB_EVENT],
  GIFT_TAB_IDS[GIFT_TAB_ARMY],
  GIFT_TAB_IDS[GIFT_TAB_BIRTHDAY],
  GIFT_TAB_IDS[GIFT_TAB_TEXTURE],
  GIFT_TAB_IDS[GIFT_TAB_LEVEL_UP],
  GIFT_TAB_IDS[GIFT_TAB_EXTRAVAGANT],
  GIFT_TAB_IDS[GIFT_TAB_AUDIO],
  GIFT_TAB_IDS[GIFT_TAB_HEAVENLY],
  GIFT_TAB_IDS[GIFT_TAB_DEFAULT],
];

/**
 * 基本的預設 gitf tab 順序
 * @see getDefaultGiftTabEpic packages/app/containers/GiftBox/epics.ts
 */
export const DEFAULT_GIFT_TABS: GiftTab[] = [
  GIFT_TAB_EVENT,
  GIFT_TAB_FRESH,
  GIFT_TAB_NO_LABEL,
];

export const ASYNC_GIFT_TABS: GiftTab[] = [
  GIFT_TAB_HISTORY,
  GIFT_TAB_LUCKY_BAG,
  GIFT_TAB_SLOT,
  GIFT_TAB_BAGGAGE,
  GIFT_TAB_GOODS,
];

// 需要 always 顯示的 tabs 清單 (除 concert mode 之外)
export const ALWAYS_DISPLAY_GIFT_TABS: GiftTab[] = [
  GIFT_TAB_HISTORY,
  GIFT_TAB_BAGGAGE,
  GIFT_TAB_GOODS,
];

/**
 * Box Tabs
 */
export const BOX_TABS = {
  GIFT: 0,
  RED_ENVELOPE: 1,
};
/**
 * Styles
 */
export const TOP_BAR_HEIGHT: number = 48; // px
export const DEFAULT_BOX_HEIGHT: number = 350 + TOP_BAR_HEIGHT; // px
export const MAX_BOX_HEIGHT: number = 0.7; // %
export const GIFT_WIDTH: number = 50; // px

/**
 * Others
 */
export const GIFT_COMBO_THRESHOLD: number = 3000; // ms
export const MAX_ACCUMULATED_LENGTH: number = 9;
export const MAX_LEVEL_UP_THRESHOLD: number = 2147483647; // use max int32

/**
 * Error
 * refs api/models/error.pb.go
 */
export const ERROR_CODE_VALUE = {
  NOT_ENOUGH_POINT: 16,
  ARMY_USER_NOT_ARMY: 13000,
  ENVELOPE_NOT_ENOUGH_POINT: 12005,
  ENVELOPE_MULTIPLE_EVENT: 12004,
  STREAMER_INVALID: 10008,
  /**
   * including error_vote_limit error
   * ref: https://17media.slack.com/archives/C02A1F78W49/p1662031542073829?thread_ts=1661912629.935949&cid=C02A1F78W49
   */
  GENERIC_ERROR: 35,
  GIFT_WRONG_REGION: 10000,
  MLEVEL_NOT_ENOUGH: 25000,
  SELF_FEEDING: 32,
};

// refs api/models/gift/gift.pb.go
export const BANNER_ACTION_TYPES = {
  NONE: 0,
  EVENT: 1,
  ARMY: 2,
  LINK: 3,
  ELITE: 4,
};

// 為了判斷 Error 訊息從哪裡來的
export enum ErrorMessageFrom {
  POKE = 'poke',
  GIFT = 'gift',
}

export const GIFT_TYPE_EXCLUDED = [GiftType.NonRealtime, GiftType.Limited];

export const KEEP_LUCKY_BAG_CEILING_INFO_POLLING_INTERVAL = 5000; // 5 seconds
