/* eslint-disable no-unused-vars */
import { MessageDescriptor } from 'react-intl';

import { Map, Record } from 'immutable-v4';
import { rgba } from 'polished';

import VIPIcon from '@/assets/ic-bdg-subs-m.svg';
import VIPIconMobile from '@/assets/ic-bdg-subs-m-mobile.svg';
import GuardianIcon from '@/assets/ic-msg-guardian.svg';
import GuardianIconMobile from '@/assets/ic-msg-guardian-mobile.svg';
import { BD_BLACK, BD_WHITE } from '@/constants/colors';

import { makeSelectLoginUserRegion } from '@17live/app/containers/LoginProvider/selectors';
import {
  CommentShadowType,
  MessageType,
  ReactionTypes,
} from '@17live/app/services/Message/Message.enums';
import { UserInfo as StreamerInfo } from '@17live/app/types/UserInfo';
import { getRegion } from '@17live/app/utils';
import { isRegionAllowedWithRegionMode } from '@17live/app/utils/regionMode';
import { COLOR_BLACK, COLOR_PRIMARY_WHITE } from '@17live/design-system/color';

import {
  BANNER_MESSAGE_DISPLAY_TIME,
  FOLLOW_TEXT_COLOR,
  GUARDIAN_BANNER_BACKGROUND,
  GUARDIAN_BANNER_COLOR,
  GUARDIAN_COLOR,
  RECEIVE_MESSAGE,
  SLOT_AND_TREASURE_CHEST_MESSAGES,
  STREAMER_COLOR,
  SYSTEM_COLOR,
  USER_GUARDIAN,
  USER_NORMAL,
  USER_STREAMER,
  USER_SYSTEM,
  USER_VIP,
  VIP_BANNER_BACKGROUND,
  VIP_BANNER_COLOR,
  VIP_COLOR,
} from './constants';
import { Chat, UserType } from './types';

const userRegionSelector = makeSelectLoginUserRegion();

export const getUserType = (
  user: {
    userID: string;
    isGuardian?: boolean;
    isVIP?: boolean;
    isSystem?: boolean;
  },
  streamerInfo: Record<StreamerInfo>
): UserType => {
  // this order is important (streamer -> guardian -> vip -> normal)
  if (streamerInfo && user.userID === streamerInfo.get('userID')) {
    return USER_STREAMER;
  } else if (user.isGuardian) {
    return USER_GUARDIAN;
  } else if (user.isVIP) {
    return USER_VIP;
  } else if (user.isSystem) {
    return USER_SYSTEM;
  }

  return USER_NORMAL;
};

export const mapUserTypeToColor = (type: UserType): string =>
  ({
    [USER_VIP]: VIP_COLOR,
    [USER_GUARDIAN]: GUARDIAN_COLOR,
    [USER_STREAMER]: STREAMER_COLOR,
    [USER_SYSTEM]: SYSTEM_COLOR,
  }[type]);

export const mapUserTypeToIcon = (type: UserType) =>
  ({
    [USER_VIP]: VIPIcon,
    [USER_GUARDIAN]: GuardianIcon,
  }[type] || '');

export const mapUserTypeToMobileIcon = (type: UserType) =>
  ({
    [USER_VIP]: VIPIconMobile,
    [USER_GUARDIAN]: GuardianIconMobile,
  }[type] || null);

export const mapUserTypeToBannerBackground = (type: UserType): string =>
  ({
    [USER_VIP]: VIP_BANNER_BACKGROUND,
    [USER_GUARDIAN]: GUARDIAN_BANNER_BACKGROUND,
  }[type] || '');

// used in mobile
export const mapUserTypeToBackgroundColor = (type: UserType): string =>
  ({
    [USER_VIP]: rgba(VIP_BANNER_BACKGROUND, 0.7),
    [USER_GUARDIAN]: rgba(GUARDIAN_BANNER_BACKGROUND, 0.8),
    [USER_STREAMER]: rgba(STREAMER_COLOR, 0.7),
  }[type] || '');

export const mapReactionToBackgroundColor = (type: ReactionTypes): string =>
  ({
    [ReactionTypes.SHARE_FB]: rgba(BD_WHITE, 0.7),
    [ReactionTypes.SHARE_17]: rgba(BD_WHITE, 0.7),
  }[type] || '');

export const mapReactionToColor = (type: ReactionTypes): string =>
  ({
    [ReactionTypes.FOLLOW]: FOLLOW_TEXT_COLOR,
    [ReactionTypes.LIKE]: BD_WHITE,
    [ReactionTypes.SHARE_17]: BD_BLACK,
    [ReactionTypes.SHARE_FB]: BD_BLACK,
  }[type] || '');

export const mapCommentShadowColor = (type?: CommentShadowType) => {
  switch (type) {
    case CommentShadowType.LIGHT:
      return COLOR_PRIMARY_WHITE;
    case CommentShadowType.DARK:
      return COLOR_BLACK;
    case CommentShadowType.NONE:
    default:
      break;
  }
};

export const mapUserTypeToBannerColor = (type: UserType): string =>
  ({
    [USER_VIP]: VIP_BANNER_COLOR,
    [USER_GUARDIAN]: GUARDIAN_BANNER_COLOR,
  }[type] || 'black');

export const filterMessageType = (...types: Array<number>) => (action: {
  type: string;
  payload: { message: { type: number } };
}) =>
  action.type === RECEIVE_MESSAGE &&
  !!types.find(type => Number(type) === action.payload.message.type);

export const messageTypeToUserType: Map<number, UserType> = {
  [MessageType.SUBSCRIBER_ENTER_LIVE]: USER_VIP,
  [MessageType.GUARDIAN_ENTER]: USER_GUARDIAN,
};

export const mapUserTypeToMessageKey = (type: UserType): string =>
  ({
    [USER_VIP]: 'subscriberEnterMsg',
    [USER_GUARDIAN]: 'displayUserMsg',
  }[type] || '');

export const mapUserTypeToMessageID = (type: UserType): string =>
  ({
    [USER_VIP]: 'vipEnterMessage',
    [USER_GUARDIAN]: 'guardianEnterMessage',
  }[type] || '');

export const mapCheckingLevelToNumber = (
  checkingLevel: number
): number | null => {
  switch (checkingLevel) {
    case 2:
      return 5;
    case 3:
      return 10;
    case 4:
      return 20;
    case 5:
      return 40;
    default:
      return null;
  }
};

export const getTransitionPercent = (
  delay: number = BANNER_MESSAGE_DISPLAY_TIME
): string => {
  const percent = (500 / delay) * 100;

  return `${percent}%, ${100 - percent}%`;
};

export const getSlotOrTreasureChestMessages = (
  type: string
): MessageDescriptor => SLOT_AND_TREASURE_CHEST_MESSAGES[type];

export const isMarqueeMessageAllowed = (
  state: Map<string, any>,
  regionMode: number,
  regions: Array<string> = []
): boolean =>
  isRegionAllowedWithRegionMode(
    userRegionSelector(state) || getRegion(),
    regionMode,
    regions
  );

/**
 * hex 為 8 碼時，api response 前兩碼為透明度，需調整順序讓 web style 正常
 */
export const transfer8bitHexCode = (hexCode?: string) => {
  if (!hexCode) {
    return;
  }
  const hex = hexCode.replace('#', '');
  return hex.length === 6
    ? `#${hex}`
    : `#${hex.substring(2, 8)}${hex.substring(0, 2)}`;
};

export const getChatProps = (
  chat: Record<Chat>
): Chat & {
  key: string;
} => {
  return {
    key: chat.get('id'),
    id: chat.get('id'),
    messageType: chat.get('messageType'),
    displayName: chat.get('displayName'),
    openID: chat.get('openID'),
    userID: chat.get('userID'),
    content: chat.get('content'),
    level: chat.get('level'),
    levelBadges: chat.get('levelBadges'),
    isGuardian: chat.get('isGuardian'),
    isVIP: chat.get('isVIP'),
    isSendAll: chat.get('isSendAll'),
    type: chat.get('type'),
    checkingLevel: chat.get('checkinLevel'),
    gift: chat.get('gift'),
    luckyBag: chat.get('luckyBag'),
    pokeInfo: chat.get('pokeInfo'),
    giftType: chat.get('giftType'),
    giftMetas: chat.get('giftMetas'),
    receiver: chat.get('receiver'),
    value: chat.get('value'),
    armyGiftFromPubnub: chat.get('armyGiftFromPubnub'),
    armySubscription: chat.get('armySubscription'),
    isInvisible: chat.get('isInvisible'),
    snacks: chat.get('snacks'),
    border: chat.get('border'),
    nameColor: transfer8bitHexCode(chat.getIn(['name', 'textColor'])),
    textColor: transfer8bitHexCode(chat.getIn(['comment', 'textColor'])),
    commentShadowColor: chat.get('commentShadowColor'),
    backgroundColor: transfer8bitHexCode(chat.get('backgroundColor')),
    prefixBadges: chat.get('prefixBadges'),
    middleBadge: chat.getIn(['middleBadge', 'URL']),
    topRightBadge: chat.getIn(['topRightBadge', 'URL']),
  };
};
